
import CompTable from "@/components/comp-table.vue"
import Message from "@/module/interactive/message"
import Request from "@/module/request/request"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "发票类型",
                    render: (h: Function, params: obj) => {
                        return { 0: "电子普通发票", 1: "专票" }[params.row.invoiceType] || "未知"
                    },
                },
                {
                    title: "发票内容",
                    key: "invoiceContent",
                },
                {
                    title: "抬头类型",
                    render: (h: Function, params: obj) => {
                        return { 0: "个人", 1: "单位" }[params.row.headerType] || "未知"
                    },
                },
                {
                    title: "抬头名称",
                    key: "headerName",
                },
                {
                    title: "审核状态",
                    render: (h: Function, params: obj) => {
                        if (params.row.approvalStatus === "1") {
                            return h(
                                "span",
                                {
                                    style: {
                                        color: params.row.file ? "#67C23A" : "#2faaf7",
                                    },
                                },
                                `审核通过 - ${params.row.file ? "已上传文件" : "未上传文件"}`
                            )
                        }

                        return h(
                            "span",
                            {
                                style: {
                                    color: ["#2faaf7", "#67C23A", "#F56C6C", "#909399"][parseInt(params.row.approvalStatus)],
                                },
                            },
                            { 0: "待审核", 1: "审核通过", 2: "审核未通过", 3: "已取消" }[params.row.approvalStatus] || "其他"
                        )
                    },
                },
                {
                    title: "申请日期",
                    key: "createdDate",
                },
                {
                    title: "操作",
                    fixed: "right",
                    slot: "row-operate",
                    width: 200,
                },
            ],
        }
    },

    methods: {
        /**
         * 同意/拒绝申请
         */
        onChangeApply(uuid: string, status: string) {
            Request.post(
                "WEB://Invoice/Approve",
                {
                    uuid: uuid,
                    approvalStatus: status,
                },
                {
                    success: "操作成功",
                }
            ).then(() => {
                // 刷新表格
                this.$refs.comp_table.refresh()
            })
        },

        /**
         * 文件上传操作事件
         */
        onUploadFile(uuid: string) {
            const input = document.createElement("input")

            input.type = "file"

            input.onchange = (evt: any) => {
                var formData = new FormData()

                formData.append("file", evt.target.files[0])
                formData.append("uuid", uuid)

                Request.post("WEB://Invoice/UploadFile", formData).then(() => {
                    Message.success("上传成功", true)
                        .onTimeout(() => {
                            this.$refs.comp_table.refresh()
                        })
                        .build()
                })
            }

            input.click()
        },
    },
}
